import Settings from "../config/settings.json";

export class JSUtil {
  static ConvertObjectMemberCase(obj) {
    if (obj != null) {
      var key,
        keys = Object.keys(obj);
      var n = keys.length;
      var newObj = {};
      while (n--) {
        key = keys[n];
        newObj[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
      }

      return newObj;
    } else {
      return null;
    }
  }

  static ConvertObjectMemberCaseLower(obj) {
    if (obj != null) {
      var key,
        keys = Object.keys(obj);
      var n = keys.length;
      var newObj = {};
      while (n--) {
        key = keys[n];
        newObj[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key];
      }

      return newObj;
    } else {
      return null;
    }
  }

  static Log(message, logLevel, source) {
    if (Settings && Settings.logLevel) {
      if (source) {
        if (Settings.logLevel[source] >= logLevel) {
          console.log(message);
        }
      } else {
        if (logLevel <= Settings.logLevel) {
          console.log(message);
        }
      }
    }
  }

  static UpperCaseFirstLetter(obj) {
    return obj.charAt(0).toUpperCase() + obj.slice(1);
  }

  static LowerCaseFirstLetter(obj) {
    return obj.charAt(0).toLowerCase() + obj.slice(1);
  }

  static isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  static isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  static date_sort_asc = function (date1, date2) {
    // This is a comparison function that will result in dates being sorted in
    // ASCENDING order. As you can see, JavaScript's native comparison operators
    // can be used to compare dates. This was news to me.
    if (date1 > date2) return 1;
    if (date1 < date2) return -1;
    return 0;
  };

  static date_sort_desc = function (date1, date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  };
}

export default JSUtil;
