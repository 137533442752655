import React, { useState, useEffect } from "react";
import Positions2 from "./Positions2";
import { Distl } from "../../options/distl";
import DateFuncs from "../../util/dateFuncs";
import { useAuth } from "react-oidc-context";

function Report2(props) {
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState();
  const pageSize = 10;

  const auth = useAuth();

  const LoadList = () => {
    if (auth.isAuthenticated) {
      Distl.GetKvs(auth).then((dataList) => setDataList(dataList));
      Distl.GetPEList(auth).then((data) => {
        if (data) {
          setData(data);
        }
      });
    }
  };

  useEffect(() => {
    LoadList();
  }, [auth]); // don't rerun.

  const GetHeader = () => {
    return (
      <div className="table1 underline">
        <div className="c w5 ralign"></div>
        <div className="c w150 ralign">Return% x Prob%</div>
      </div>
    );
  };

  const PositionPage = (change) => {
    var results = data;
    var newPage = page + change;

    if (newPage < 0) {
      newPage = 0;
    }

    results = ApplyFilter(results, filter);

    if (newPage >= results.length / pageSize) {
      newPage = page;
    }

    setPage(newPage);
  };

  const ApplyFilter = (results, filter) => {
    if (filter) {
      results = results.filter((p) => {
        return p.DisplayName.includes(filter.toUpperCase());
      });
    }

    return results;
  };

  const search = (e) => {
    setPage(0);
    setFilter(e.target.value);
  };

  const GetOpt2 = (e) => {
    var distlId = e.target.value;
    Distl.GetPEList(auth, distlId).then((data) => setData(data));
  };

  var positionTable = {};

  var count = 0;
  var currentStart = page * pageSize;
  var currentEnd = (page + 1) * pageSize;

  if (data && Array.isArray(data)) {
    var results = ApplyFilter(data, filter);

    count = results.length;
    if (currentEnd > count) {
      currentEnd = count;
    }

    positionTable = results.slice(currentStart, currentEnd).map((p, i) => {
      return <Positions2 data={p} key={i} user={props.user} />;
    });
  }

  return (
    <div>
      <SelectDistl user={props.user} onChange={GetOpt2} data={dataList} />
      <div className="button" onClick={LoadList}>
        Refresh List
      </div>
      <input type="text" id="symbolSearch" onChange={search}></input>
      {GetHeader()}
      {positionTable}
      <div className="table1 center">
        <div className="c button" onClick={() => PositionPage(-1)}>
          &lt;
        </div>
        <div className="c w5"> {currentStart + 1 + " - " + currentEnd + " / " + count}</div>
        <div className="c button" onClick={() => PositionPage(1)}>
          &gt;
        </div>
      </div>
    </div>
  );
}

function SelectDistl(props) {
  const onChange = (selectedId) => {
    props.onChange(selectedId);
  };

  if (props.data && Array.isArray(props.data)) {
    var kvsTable = props.data.map((p, i) => {
      var date = new Date(p.createDate);

      console.log("date: ", p.createDate);
      console.log("pos: ", p);
      return (
        <option value={p.id} key={i}>
          {DateFuncs.ShortDateTimeWDay(date) + ": " + p.type}
        </option>
      );
    });
  }

  return (
    <select id="distlKvs" onChange={onChange}>
      {kvsTable}
    </select>
  );
}

export default Report2;
