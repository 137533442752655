import React, { createContext, useReducer, ReactElement } from 'react';
import { IContextBase } from "../../context/IContextBase";
import { Nullable } from "../../entities/BaseTypes";
import { User, IUser, IUserAction, UserActionType } from "../entities/User";
import JSUtil from "../../util/jsUtil";

const userReducer = (state: IUser, action: IUserAction): IUser => {
    JSUtil.Log("Reducing UserAction", 2, "user");
    switch (action.action) {
        case UserActionType.LogIn:
            return new User(action.payload);
        case UserActionType.SetUser:
            return new User(action.payload);
        case UserActionType.LogOut:
            return new User({ authenticated: false });
    }
};

export const UserContext = createContext<Nullable<IContextBase<IUser, IUserAction>>>(null);

export const UserProvider: React.FC<IProviderPropsBase<IUser>> = ({ children, value }) => {
    const [state, dispatch] = useReducer(userReducer, value);

    return (
        <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>
    );
};

export interface IProviderPropsBase<TValue> {
    value: TValue;
    children: ReactElement;
}
