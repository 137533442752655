import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import User from "../User";
import Settings from "../../config/settings.json";
import { UserContext } from "../context/UserContext";
import { UserActionType } from "../entities/User";
import JSUtil from "../../util/jsUtil";
import MenuLink from "../../components/MenuLink";
import { IAuthHandlerProps } from '../../entities/BaseTypes';

function AuthHandler(props: IAuthHandlerProps) {
  const auth = useAuth();
  const userContext = useContext(UserContext);
  const [stopLogin, setStopLogin] = useState(false);
  const navigate = useNavigate();

  const failedLoginCleanup = (error: string | null = null) => {
    console.log("Cleaning up failed login. log out saber user, stop login and stop silent renew:", error);
    userContext?.dispatch({ action: UserActionType.LogOut });
    setStopLogin(true);
    auth.stopSilentRenew();
  };

  const loadUser = () => {
    if (auth && auth.isAuthenticated && !userContext?.state.authenticated) {
      User.GetUser(auth)
        .then((user: any) => { // TODO: prolly need SaberUser from back-end here.
          if (user) {
            localStorage.setItem(Settings.localStoreAutoSignIn, user.email);
            userContext?.dispatch({ action: UserActionType.LogIn, payload: { ...user } });

            if (stopLogin) {
              setStopLogin(false);
              auth.startSilentRenew();
            }

            var loginRedirect = localStorage.getItem(Settings.localStoreLoginRedirect);
            if (loginRedirect) {
              localStorage.removeItem(Settings.localStoreLoginRedirect);
              navigate(loginRedirect);
            }
          }
        })
        .catch((error: string) => {
          failedLoginCleanup(error);
        });
    }
  };

  const login = () => {

    // why pass in loginhint when we can do here.

    var autoSignIn = localStorage.getItem(Settings.localStoreAutoSignIn);

    console.log("login clicked.", auth, userContext?.state);
    if (auth && !auth.isLoading && (auth.error || !auth.isAuthenticated)) {
      JSUtil.Log("calling signin from AuthHandler location 2.", 2, "auth");

      // ideally, this path woudn't include authhandler.
      if (window.location.pathname !== "/a") {
        const args = autoSignIn ? { login_hint: autoSignIn } : undefined;

        var relativeUrl = window.location.href.replace(/^(?:\/\/|[^/]+)*\//, '');
        if (relativeUrl) {
          localStorage.setItem(Settings.localStoreLoginRedirect, relativeUrl);
        }

        auth.signinRedirect(args).catch((error) => { failedLoginCleanup(error); });
      }
    } else {
      JSUtil.Log("Authenticated. Skipping auth.", 2, "auth");
    }
  };

  useEffect(() => {
    var autoSignIn = localStorage.getItem(Settings.localStoreAutoSignIn);
    var loginRedirect = localStorage.getItem("loginRedirect");
    JSUtil.Log("loading auth handler." + autoSignIn + ", redir: " + loginRedirect + ", " + auth.error, 2, "login");

    if (auth.error && !stopLogin) {
      if (auth.error.message === "interaction_required") {
        // TODO: could we need a login redirect here??
        // Leave a window open on a shared link to test.
        login();
      }
      else {
        failedLoginCleanup(auth?.error?.message);
      }
    } else if (autoSignIn && !auth.isAuthenticated && !auth.isLoading) {
      console.log("logging in auth handler.");
      login();
    }
    else if (auth.isAuthenticated) {
      loadUser();
    }
  }, [auth]);

  if (userContext?.state.authenticated) {
    return (
      <MenuLink className={props.dynClass ?? ''} to="/user">
        Hello {userContext.state.firstName}!
      </MenuLink>
    );
  } else {
    return (
      <div className={props.dynClass ?? ''} onClick={() => { login(); }}>
        Sign In
      </div>
    );
  }
}

export default AuthHandler;
