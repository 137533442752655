import DateFuncs from "./dateFuncs";
import { AuthContextProps } from "react-oidc-context";

class RetryToken {
  retryCount: number = 1;
  lastAttempt: Date = new Date();
  max: number = 5;
}

export class HttpUtil {
  debug = false;

  static HandleResponseCodes(response: Response, errorHandlers?: ((response: Response) => {})[]): Promise<Response> {
    return new Promise((resolve, reject) => {
      if (typeof response != "undefined" && response != null) {
        if (typeof errorHandlers != "undefined" && errorHandlers != null) {
          if (errorHandlers[response.status] != null) {
            errorHandlers[response.status](response);
          } else {
            reject(response);
          }
        }

        if (!response.ok) {
          console.error("Unhandled network exception: " + response.status + ", " + response.statusText);
          reject(response);
        }
      }
      resolve(response);
    });
  }

  static getTimeout = (retryToken: RetryToken) => {
    console.log("timeout is: ", retryToken);
    if (retryToken && retryToken.retryCount) {
      let timeout = retryToken.retryCount * 1000;

      DateFuncs.AddDays();

      let timeToRun = retryToken.lastAttempt.getTime() + timeout;
      let sleep = timeToRun - Date.now();

      console.log("sleep: ", sleep);
      if (sleep > 0) {
        if (sleep > 60000) {
          return 60000;
        }
        console.log("sleep is: ", sleep);
        return sleep;
      }
    }
    return 1000;
  };

  // I actually don't even need retry since the token auto refreshes.
  static Fetch(request: Request, authHook: AuthContextProps, noAuth?: boolean) {
    return new Promise((resolve, reject) => {
      if (!request) {
        reject("Request is required.");
      }

      if (authHook && authHook.isLoading) {
        reject("auth not loaded.");
      }

      if (!authHook) {
        return fetch(request.url, {
          method: request.method ?? "POST",
          mode: "cors",
          headers: {
            "Content-Type": "text/html",
            Accept: "application/json",
          },
          body: JSON.stringify(request.body),
        })
          .then((res: Response) => {
            return this.HandleResponseCodes(res);
          })
          .then((res: any) => {
            resolve(res.json());
            return;
          })
          .catch((error) => {
            console.log("Fetch error: " + request.method + ":" + request.url + " failed.", error);
            reject(error);
          });
      } else {
        const token = authHook && authHook.user ? authHook.user.id_token : null;

        if (token) {
          return fetch(request.url, {
            method: request.method ?? "POST",
            mode: "cors",
            headers: {
              "Content-Type": "text/html",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(request.body),
          })
            .then((res: Response) => {
              return this.HandleResponseCodes(res);
            })
            .then((res: any) => resolve(res.json()))
            .catch((error) => {
              console.log("Fetch error: " + request.method + ":" + request.url + " failed.", error);
              reject(error);
            });
        } else {
          //alert("Please log in to use this feature.");
          reject("Token not provided. Specify noAuth if intentional:" + request.url);
        }
      }
    });
  }

  static FetchAs<T>(request: Request, authHook?: AuthContextProps): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!request) {
        reject("Request is required.");
      }

      if (authHook && authHook.isLoading) {
        reject("auth not loaded.");
      }

      var headers: HeadersInit = {
        "Content-Type": "text/html",
        Accept: "application/json",
      };

      if (authHook) {
        const token = authHook && authHook.user ? authHook.user.id_token : null;

        if (token) {
          headers = {
            "Content-Type": "text/html",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          };
        } else {
          reject("Token not provided. Specify noAuth if intentional:" + request.url);
        }
      }

      return fetch(request.url, {
        method: request.method ?? "POST",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(request.body),
      })
        .then((res: Response) => {
          return this.HandleResponseCodes(res);
        })
        .then((res: Response) => {
          resolve(res.json().then((data) => data as T));
        })
        .catch((error) => {
          console.log("Fetch error: " + request.method + ":" + request.url + " failed.", error);
          reject(error);
        });
    });
  }
}
