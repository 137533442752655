import React, { useState, useEffect } from "react";
import PositionsHttp from "../../options/positionsHttp";
import { useAuth } from "react-oidc-context";

import PositionEvaluatorComponent from "./PositionEvaluator";

export function PositionManager(props) {
  const [page, setPage] = useState(0);
  const [pe, setPe] = useState();
  const [data, setData] = useState();

  const auth = useAuth();

  const pageSize = 5;

  const isLoggedIn = () => {
    return auth && auth.user;
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isLoggedIn) {
      PositionsHttp.GetPositions(auth)
        .then((data) => {
          if (isSubscribed) {
            setData(data);
          }
        })
        .catch((error) => {
          console.log("Error getting positions:", error);
        });
    }
    return () => (isSubscribed = false);
  }, [props.reloadTime]); // don't rerun.

  const PositionPage = (change) => {
    var newPage = page + change;

    if (newPage < 0) {
      newPage = 0;
    }

    if (newPage >= data.length / pageSize) {
      newPage = page;
    }

    setPage(newPage);
  };

  const PositionPageSet = (page) => {
    var newPage = page;

    if (newPage < 0) {
      newPage = 0;
    }

    if (newPage >= data.length / pageSize) {
      newPage = page;
    }

    setPage(newPage);
  };

  const PositionPageLast = () => {
    var lastPage = Math.floor((data.length - 1) / pageSize);

    setPage(lastPage);
  };

  const DeletePE = (positionId) => {
    PositionsHttp.DeletePosition(auth, positionId)
      .then(() => PositionsHttp.GetPositions(auth).then((data) => setData(data)))
      .catch((error) => {
        console.log("Delete position failed.", error);
      });
  };

  const SelectPE = (positionId) => {
    // load position with a callback
    PositionsHttp.GetPosition(auth, positionId).then((data) => {
      if (data != null) {
        setPe(data);
      }
    });
  };

  const onClearPositions = (index) => {
    setPe(null);
  };

  var currentStart = page * pageSize;
  var currentEnd = (page + 1) * pageSize;

  var count = 0;

  var savedPositions = [];

  if (data && Array.isArray(data)) {
    count = data.length;

    if (currentEnd > count) {
      currentEnd = count;
    }

    savedPositions = data.slice(currentStart, currentEnd).map((p, i) => {
      return (
        <div className="table1 w100" key={i}>
          <div
            className="button c "
            onClick={() => {
              if (window.confirm("Are you sure you want to delete " + p.description + "?")) {
                DeletePE(p.id);
              }
            }}
          >
            X
          </div>
          <div className="button c w4" onClick={() => SelectPE(p.id)}>
            {p.description}: {p.createDate?.toString()}
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      {savedPositions}
      {count > 0 && (
        <div className="table1 center">
          <div className="c button" onClick={() => PositionPageSet(0)}>
            &lt;&lt;
          </div>
          <div className="c button" onClick={() => PositionPage(-1)}>
            &lt;
          </div>
          <div className="c w5"> {currentStart + 1 + " - " + currentEnd + " / " + count}</div>
          <div className="c button" onClick={() => PositionPage(1)}>
            &gt;
          </div>
          <div className="c button" onClick={() => PositionPageLast()}>
            &gt;&gt;
          </div>
        </div>
      )}

      {pe != null && (
        <PositionEvaluatorComponent
          data={pe}
          includeOpen={true}
          includeEnding={false}
          showGreeks={true}
          onUpdated={props.onUpdated}
          onClearPositions={onClearPositions}
        />
      )}
    </div>
  );
}

export default PositionManager;
