import React, { useState, useEffect, useContext } from "react";
import Settings from "../config/settings.json";
import { AdminEmail } from "../admin/adminEmail";
import { AdminUser } from "../usermodule/adminUser";
import { useAuth } from "react-oidc-context";
import { UserContext } from "../usermodule/context/UserContext";

function Admin(this: any, props: any) {
  const [users, setUsers] = useState<any[]>([]);
  const auth = useAuth();
  const userContext = useContext(UserContext);

  const onUpdateUser = (event: any) => {
    event.preventDefault();

    var t = event.target;

    var stateUser = users.find((u) => u.email === t.email.value);

    if (t != null) {
      stateUser.firstName = t.firstName.value;
      stateUser.lastName = t.lastName.value;
      stateUser.email = t.email.value;
      stateUser.services = {
        hasAdmin: t.hasAdmin.checked,
        hasOptions: t.hasOptions.checked,
        hasRevLight: t.hasRevLight.checked,
        hasReports: t.hasReports.checked
      };

      stateUser.disabled = t.disabled.checked;
      stateUser.edit = false;

      AdminUser.PostUser(stateUser, auth);

      setUsers(
        users.map((u) => {
          if (u.email === t.email.value) {
            u = stateUser;
          }
          return u;
        })
      );
    } else {
      console.log("target was null.");
    }
  };

  const onUserCancel = (event: any, email: string) => {
    event.preventDefault();

    setUsers(
      users.map((u) => {
        if (u.email === email) {
          u.edit = false;
        }

        return u;
      })
    );
  };

  const onUserEdit = (event: any, email: string) => {
    event.preventDefault();

    setUsers(
      users.map((u) => {
        if (u.email === email) {
          u.edit = true;
        }

        return u;
      })
    );
  };

  const onUserDelete = (event: any, email: string) => {
    event.preventDefault();
    if (email != null && typeof email != "undefined") {
      if (window.confirm("Are you sure you want to delete " + email + "?")) {
        setUsers(
          users.filter((u) => {
            if (u.email !== email) {
              return u;
            }

            return null;
          })
        );

        AdminUser.DeleteUser({ email: email }, auth);
      }
    }
  };

  const onUserAdd = (event: any) => {
    event.preventDefault();

    var t = event.target;

    if (t != null) {
      if (typeof t.email == "undefined" || t.email.value == null || t.email.value === "") {
        return;
      }

      var user = {
        firstName: t.firstName.value,
        lastName: t.lastName.value,
        email: t.email.value,
        services: {
          hasAdmin: t.hasAdmin.checked,
          hasOptions: t.hasOptions.checked,
          hasRevLight: t.hasRevLight.checked,
          hasReports: t.hasReports.checked
        },
        disabled: t.disabled.checked,
      };

      users.push(user);
      setUsers([...users]);

      t.firstName.value = "";
      t.lastName.value = "";
      t.email.value = "";
      t.hasAdmin.checked = false;
      t.hasOptions.checked = false;
      t.hasRevLight.checked = false;
      t.hasReports.checked = false;
      t.disabled.checked = false;

      AdminUser.PostUser(user, auth);
    } else {
      console.log("target was null.");
    }
  };

  /*
  const onSendWelcomeEmail = (event, email) => {
    event.preventDefault();

    if (email !== null && typeof email !== "undefined" && email !== "") {
      if (window.confirm("Are you sure you would like to welcome " + email + "?")) {
        var body =
          '<html><body><div>Welcome! You have been invited to SaberFin.io. Please click <a href="' +
          Settings.hostName +
          "/login?email=" +
          email +
          '" > Here</a > to access your account.</div ></body></html>';
        var subject = "Welcome to SaberFin";
        AdminEmail.SendEmail(email, subject, body, auth);
      }
    }
  };
  */

  const onSendInvite = (event: any, email: string) => {
    event.preventDefault();

    if (email != null && typeof email != "undefined" && email !== "") {
      if (window.confirm("Are you sure you would like to welcome " + email + "?")) {
        var subject = "Welcome to SaberFin";
        AdminEmail.SendInvite(email, email, subject, auth);
      }
    }
  };

  const onSendCompose = (event: any, email: string) => {
    event.preventDefault();

    if (email != null && typeof email != "undefined" && email !== "") {
      var subject = "Welcome to SaberFin";
      AdminEmail.SendInvite(Settings.adminEmail, email, subject, auth);
    }
  };

  // Oooh I should just generate it and copy it to the clipboard.
  const generateAPIKey = (event: any) => {

    AdminUser.GenerateAPIKey(auth).then((tokenResult: any) => {
      if (tokenResult?.token) {
        navigator.clipboard.writeText(tokenResult.token);
        alert("API Key copied to clipboard");
      }
      else {
        console.error("Response did not contain token.", tokenResult);
      }
    });


  };

  useEffect(() => {
    if (auth && auth.user) {
      AdminUser.GetUsers(auth)
        .then((data: any) => {
          setUsers(data);
        })
        .catch((error) => console.log("error in getusers.", error));
    }
  }, [auth]);

  var userTable: any = <div />;
  var userCount = 0;

  if (users != null && users.constructor === Array) {
    userCount = users.length;
    userTable = users.map((u, i) => {
      var usvcs = u.services || {
        hasAdmin: false,
        hasOptions: false,
        hasRevLight: false,
        hasReports: false
      };

      return (
        <form onSubmit={onUpdateUser} key={i}>
          {u.edit === true ? (
            <div className="table1">
              <input type="hidden" id="email" value={u.email} />
              <div className="c w1">
                <button>S</button>
                <button onClick={(e) => onUserCancel(e, u.email)}>C</button>
              </div>
              <div className="c w2">
                <input type="text" id="firstName" defaultValue={u.firstName} />
                <input type="text" id="lastName" defaultValue={u.lastName} />
              </div>
              <div className="c w2">{u.email}</div>
              <div className="c w2"></div>

              <div className="c w1">
                <input type="checkbox" id="hasAdmin" defaultChecked={usvcs.hasAdmin} />
              </div>
              <div className="c w1">
                <input type="checkbox" id="hasOptions" defaultChecked={usvcs.hasOptions} />
              </div>
              <div className="c w1">
                <input type="checkbox" id="hasRevLight" defaultChecked={usvcs.hasRevLight} />
              </div>
              <div className="c w1">
                <input type="checkbox" id="hasReports" defaultChecked={usvcs.hasReports} />
              </div>
              <div className="c w1">
                <input type="checkbox" id="disabled" defaultChecked={u.disabled} />
              </div>

              <div className="c w3">{u.lastLogin}</div>
              <div className="c w3">{u.lastAccess}</div>
            </div>
          ) : (
            <div className="table1">
              <input type="hidden" id="email" value={u.email} />
              <div className="c w1">
                <button onClick={(e) => onUserEdit(e, u.email)}>E</button>
                <button onClick={(e) => onUserDelete(e, u.email)}>X</button>
              </div>
              <div className="c w2">
                {u.firstName} {u.lastName}
              </div>
              <div className="c w2 menuItem" onClick={(e) => onSendInvite(e, u.email)} title="Send Invite">
                {u.email}
              </div>
              <div className="c w2 menuItem" onClick={(e) => onSendCompose(e, u.email)} title="Compose Invite">
                Compose
              </div>
              <div className="c w1">{usvcs.hasAdmin ? "Yes" : "No"}</div>
              <div className="c w1">{usvcs.hasOptions ? "Yes" : "No"}</div>
              <div className="c w1">{usvcs.hasRevLight ? "Yes" : "No"}</div>
              <div className="c w1">{usvcs.hasReports ? "Yes" : "No"}</div>
              <div className="c w1">{u.disabled ? "Yes" : "No"}</div>
              <div className="c w3">{u.lastLogin}</div>
              <div className="c w3">{u.lastAccess}</div>
            </div>
          )}
        </form>
      );
    });
  }

  return (
    <div className="">
      {
        <div>
          <div className="bodyHeader">
            Howdy Admin {userContext?.state.firstName} {userContext?.state.lastName}!
          </div>
          <div className="adminPanel">
            <div>
              Users: {userCount}
              <div className="table1">
                <div className="c w1"></div>
                <div className="c w2">User Name</div>
                <div className="c w2">User Email</div>
                <div className="c w2">Invite</div>
                <div className="c w1">Admin</div>
                <div className="c w1">Options</div>
                <div className="c w1">RevLight</div>
                <div className="c w1">Reports</div>
                <div className="c w1">Disabled</div>
                <div className="c w3">Last Login</div>
                <div className="c w3">Last Access</div>
              </div>
              {userTable}
              <form onSubmit={onUserAdd.bind(this)}>
                <div className="table1">
                  <div className="c w1">
                    <button>+</button>
                  </div>
                  <div className="c w2">
                    <input type="text" id="firstName" />
                    <input type="text" id="lastName" />
                  </div>
                  <div className="c w2">
                    <input type="text" id="email" />
                  </div>
                  <div className="c w2"></div>
                  <div className="c w1">
                    <input type="checkbox" id="hasAdmin" />
                  </div>
                  <div className="c w1">
                    <input type="checkbox" id="hasOptions" />
                  </div>
                  <div className="c w1">
                    <input type="checkbox" id="hasRevLight" />
                  </div>
                  <div className="c w1">
                    <input type="checkbox" id="hasReports" />
                  </div>
                  <div className="c w1">
                    <input type="checkbox" id="disabled" />
                  </div>
                </div>
              </form>
            </div>
            <br />
            <div><button onClick={(e) => generateAPIKey(e)}>Generate API Key</button>
            </div>

          </div>
        </div>
      }
    </div>
  );
}

export default Admin;
