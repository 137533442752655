import React from "react";

function Logo(props) {
  return (
    <svg className="logoImage" alt="saberfin logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 563 230">
      <rect width="100%" height="100%" fill="lightskyblue" />
      <path
        id="Unnamed #1"
        fill="#ffffff"
        strokeWidth="1"
        d="M 11.00,230.00
           C 221.00,230.00 201.00,0.00 391.00,0.00
             256.17,119.62 411.00,230.00 541.00,230.00
             541.00,230.00 11.00,230.00 11.00,230.00 Z"
      />

      <path
        id="Unnamed #2"
        fill="none"
        stroke="#255aab"
        strokeWidth="30"
        d="M 1.00,200.00
           C 45.00,207.33 130.33,187.33 131.00,150.00
             131.00,200.00 281.00,210.00 281.00,150.00
             279.67,208.67 451.67,214.00 451.00,150.00
             451.00,214.00 559.67,200.67 561.00,200.00"
        opacity=".45"
      />

      <text x="0" y="140" className="logoFont">
        SaberFin
      </text>
    </svg>
  );
}

export default Logo;
