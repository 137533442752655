import Settings from "../config/settings";
import { HttpUtil } from "../util/httpUtil";
import JSUtil from "../util/jsUtil";

import { PositionEvaluator } from "min-types/MinClasses";
import { MinTypeBuilders } from "min-types/minTypeBuilders";

export class Distl {
  static GetPEList(auth, distlId) {
    if (typeof distlId == "undefined" || distlId == null) {
      distlId = "";
    }

    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/minoa/v1/distl/pes?id=" + distlId,
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null && Array.isArray(data)) {
          var outputData = [];

          data.forEach((pe, i) => {
            pe = JSUtil.ConvertObjectMemberCase(pe);
            outputData.push(pe);
          });
          outputData = outputData.sort((a, b) => {
            return b.Description - a.Description;
          });

          return outputData;
        }
      })
      .catch((err) => {});
  }

  static GetPE(auth, peId) {
    if (typeof peId == "undefined" || peId == null) {
      peId = "";
    }
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/minoa/v1/distl/pe?id=" + peId,
      },
      auth
    )
      .then((data) => {
        return MinTypeBuilders.buildPositionEvaluator(data).then((pe) => {
          return pe;
        });
      })
      .catch((err) => {});
  }

  static GetDistilId(auth, distlId) {
    if (typeof distlId == "undefined" || distlId == null) {
      distlId = "";
    }

    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/minoa/v1/distl/kv?id=" + distlId,
      },
      auth
    )
      .then((data) => {
        // TODO: needs testing
        return Promise.all(data.map(async (d) => await MinTypeBuilders.buildPositionEvaluator(d)));
      })
      .catch((err) => {});
  }

  static GetKvs(auth) {
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/minoa/v1/distl/kvs",
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null && Array.isArray(data)) {
          return data;
        }
      })
      .catch((err) => {});
  }
}
