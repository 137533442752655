class DateFuncs {
  static months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  static days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  static DateTime(d) {
    if (!d) {
      d = new Date();
    }

    if (d instanceof Date)
      var minutes = d.getMinutes().toString().length === 1 ? "0" + d.getMinutes() : d.getMinutes(),
        hours = d.getHours().toString().length === 1 ? "0" + d.getHours() : d.getHours(),
        ampm = d.getHours() >= 12 ? "pm" : "am";

    if (d instanceof Date)
      return this.days[d.getDay()] + " " + this.months[d.getMonth()] + " " + d.getDate() + " " + d.getFullYear() + " " + hours + ":" + minutes + ampm;
  }

  static Date(d) {
    if (!d) {
      d = new Date();
    }

    if (d instanceof Date) return this.days[d.getDay()] + " " + this.months[d.getMonth()] + " " + d.getDate() + " " + d.getFullYear();
  }

  static ShortDate(d) {
    if (!d) {
      d = new Date();
    }

    if (d instanceof Date)
      return d.getYear() + 1900 + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + d.getDate().toString().padStart(2, "0");
  }

  static ShortDateWDay(d) {
    if (!d) {
      d = new Date();
    }

    if (d instanceof Date) {
      return (
        d.getYear() +
        1900 +
        "-" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        d.getDate().toString().padStart(2, "0") +
        " " +
        this.days[d.getDay()]
      );
    }
  }

  static ShortDateTime(d) {
    if (!d) {
      d = new Date();
    }

    if (d instanceof Date) {
      return (
        d.getYear() +
        1900 +
        "-" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        d.getDate().toString().padStart(2, "0") +
        " " +
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0") +
        ":" +
        d.getSeconds().toString().padStart(2, "0")
      );
    }
  }

  static ShortDateTimeWDay(d) {
    if (!d) {
      d = new Date();
    }

    if (d instanceof Date) {
      return (
        this.days[d.getDay()] +
        " " +
        (d.getYear() + 1900) +
        "-" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        d.getDate().toString().padStart(2, "0") +
        " " +
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0") +
        ":" +
        d.getSeconds().toString().padStart(2, "0")
      );
    }
  }

  static TotalYears(timespan) {
    return Math.floor(timespan / (1000 * 60 * 60 * 24 * 365));
  }
  static TotalDays(timespan) {
    return Math.floor(timespan / (1000 * 60 * 60 * 24));
  }
  static TotalHours(timespan) {
    return Math.floor(timespan / (1000 * 60 * 60));
  }
  static TotalSeconds(timespan) {
    return Math.floor(timespan / (1000 * 60));
  }

  static AddDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  static Timespan(date1, date2) {
    return date1 - date2;
  }
}

export default DateFuncs;
