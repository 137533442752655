import React, { useState, useEffect } from "react";
import DateFuncs from "../../util/dateFuncs";
import Watches from "../../options/watches";
import PositionsHttp from "../../options/positionsHttp";
import { useAuth } from "react-oidc-context";
import Settings from "../../config/settings.json";

import { BrokerPosition2, PositionEvaluator, PriceHeader } from "min-types/MinClasses";
import { MinTypeBuilders } from "min-types/minTypeBuilders";

function PositionEvaluatorComponent(props: {
  displayChanges?: number[], data: PositionEvaluator, onUpdated?: () => {},
  hideClear?: boolean, hideSave?: boolean; onClearPositions?: (index: number) => void,
  collapsed?: boolean, showGreeks?: boolean, index?: number;
}) {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [dataState, setData] = useState<PositionEvaluator>();

  const auth = useAuth();

  useEffect(() => {
    if (props.collapsed && !isHidden) {
      setIsHidden(true);
    }

    if (props.data) {
      if (props.displayChanges) {
        props.data.LoadPricing(props.displayChanges);
      }

      MinTypeBuilders.buildPositionEvaluator(props.data).then((d) => {
        setData(d);
      });
    }
  }, [props]);

  const addPercentChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e && e.currentTarget && e.key == "Enter") {
      e.preventDefault();

      var percentChange = parseFloat(e.currentTarget.value) / 100;
      e.currentTarget.value = "";

      if (percentChange && dataState) {
        MinTypeBuilders.buildPositionEvaluator(dataState).then((data) => {
          data.LoadPricingForUnderlierChange(1 + percentChange);
          setData(data);
        });
      }
    }
  };

  const SavePositions = (pes?: PositionEvaluator) => {
    if (!pes) { return; }
    return PositionsHttp.SavePositionEval(auth, pes)
      .then((data) => {
        setData(data);
        if (props.onUpdated) {
          props.onUpdated();
        }
        return data;
      })
      .catch((error) => {
        console.log("Couldn't save position: ", error);
      });
  };

  const UpdatePrices = (type: string, data?: PositionEvaluator) => {
    var quoteDefinitions = data?.GetQuoteDefinitions();

    // TODO: only update the ones that are not expired!
    // everything else can be set to 0.
    if (quoteDefinitions && data) {
      Watches.GetPositionQuotes3(auth, quoteDefinitions.symbols, quoteDefinitions.optionChains).then((d: any) => {
        if (d) {
          data.AddPrices(type, d, true);
          MinTypeBuilders.buildPositionEvaluator(data).then((d) => { setData(d); });
        }
      });
    }
  };

  let pe = dataState;

  if (!pe) {
    return <div>Position not found. Please check your url.</div>;
  }

  const priceHeaders = pe.PriceHeaders();

  var positionValueTable = null;
  var positionsOnly = null;
  var greeks = null;

  var quoteDate: Date | null | undefined;

  var deltaTotal = 0;

  if (!props.collapsed && pe._positions) {
    positionValueTable = pe._positions.sort((a, b) => (a?.symbol ?? "") < (b?.symbol ?? "") ? -1 : 1)
      .map((p, i) => {
        {
          if (quoteDate) {
            quoteDate = p?.openQuote?.quote?.asOfDate;
          }
          return <PositionValue key={i} data={p} priceHeaders={priceHeaders} />;
        }
      });

    positionsOnly = (
      <div className="table1">
        {pe._positions.sort((a, b) => (a?.symbol ?? "") < (b?.symbol ?? "") ? -1 : 1)
          .map((p, i) => {
            if (quoteDate == null) {
              quoteDate = p?.openQuote?.quote?.asOfDate;
            }
            return <PositionCompact key={i} data={p} priceHeaders={priceHeaders} />;
          })}
      </div>
    );

    greeks = pe._positions.map((p, i) => {
      if (p.EQuote != null) {
        var delta = p.EQuote.greeks?.delta ?? 1; // if it is an equity, the delta is 100.
        deltaTotal += delta * (p.quantity ?? 1);
        return <Greek data={p} key={i} />;
      }

      return null;
    });
  }

  const isAllExpired = () => {
    let allExpired = true;
    const currentDate = DateFuncs.ShortDate(new Date());

    pe?._positions?.forEach((p) => {
      if ((p.daysToExpiration ?? 0 > 0) || DateFuncs.ShortDate(p.expirationDate) == currentDate) {
        allExpired = false;
        return;
      }
    });

    return allExpired;
  };

  const greekSummary = (
    <div className="table1">
      <div className="c w5 b">Totals:</div>
      <div className="c w90 ra" />
      <div className="c w6 ra">{deltaTotal.toFixed(3)}</div>
      <div className="c w6 ra" />
      <div className="c w6 ra" />
      <div className="c w6 ra" />
      <div className="c w6 ra" />
    </div>
  );

  const share = () => {
    if (pe && pe.id) {
      var message = Settings.hostName + "/p/" + pe.id;
      copyToClipboard(message);
    } else if (pe) {
      // save first.
      SavePositions(pe)?.then((peNew) => {
        if (peNew) {
          var message = Settings.hostName + "/p/" + peNew.id;
          copyToClipboard(message);
        }
      });
    }
  };

  const copyToClipboard = (message: string) => {
    navigator.clipboard.writeText(message);
    alert("Position URL copied to clipboard");
  };

  var header = <PositionHeader priceHeaders={priceHeaders} />;
  var headerCompact = <PositionHeader priceHeaders={priceHeaders} short={true} />;
  var summary = <PositionClosingSummary data={pe} />;
  var summary2 = <PositionClosingSummaryByColumn data={pe} />;
  var compact = <PositionCompactSummary data={pe} priceHeaders={priceHeaders} />;

  return (
    <div>
      {!props.collapsed && positionValueTable != null && (
        <div>
          Add underlier percent change: <input type="text" id="percentChange" onKeyDown={addPercentChange}></input>%
          {pe.primeUnderlier && (
            <div>
              <div className="table1">
                {" "}
                <div className="c w1"></div> <div className="c w1">{pe.primeUnderlier.symbol}</div>{" "}
              </div>
              <div className="table1">
                {" "}
                <div className="c w1">Current $</div> <div className="c w1">{pe.primeUnderlier.latestPrice?.toFixed(2)}</div>{" "}
              </div>
              <div className="table1">
                {" "}
                <div className="c w1">Volatility %</div> <div className="c w1">{pe.primeUnderlier.volatility?.toFixed(1)}</div>{" "}
              </div>
            </div>
          )}
          <div className="button" onClick={share}>
            Share with Clipboard
          </div>
          {!props.hideClear && (
            <div className="button" onClick={() => props.onClearPositions != null && props.onClearPositions(props.index ?? 0)}>
              {" "}
              Clear{" "}
            </div>
          )}
          {!props.hideSave && (
            <div className="button" onClick={() => SavePositions(pe)}>
              {" "}
              Save{" "}
            </div>
          )}
          <div className="table1 underline">
            {!isAllExpired() && (
              <div>
                Open $ as of {DateFuncs.ShortDateTime(quoteDate)} -{" "}
                <span className="button" onClick={() => UpdatePrices("open", pe)}>
                  {" "}
                  Update
                </span>
              </div>
            )}
            {!isAllExpired() && (
              <div>
                Compare to Current -{" "}
                <span className="button" onClick={() => UpdatePrices("current", pe)}>
                  {" "}
                  Update
                </span>
              </div>
            )}
            <div className="desktopOnly">
              {" "}
              {header} {positionValueTable} {summary}{" "}
            </div>
            <div className="mobileOnly underline ">
              {" "}
              {headerCompact} {positionsOnly}{" "}
            </div>
            <div className="mobileOnly underline">{compact}</div>
            {props.showGreeks && (
              <div>
                {" "}
                {GreekHeader(true)} {greeks} {greekSummary}{" "}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionEvaluatorComponent;

interface PositionSummaryProps {
  priceHeaders?: PriceHeader[],
  data: PositionEvaluator;
}

function PositionCompactSummary(props: PositionSummaryProps) {
  var pe = props.data;

  var display = pe.PriceHeaders()?.map((ph, i) => {
    var c = "c w6 ralign ";
    pe.SetCurrentPriceType(ph.key);
    return (
      <div className={c} key={i}>
        <div>{ph.display}</div>
        <div>{ph.subtitle}</div>
        <div>{pe.ReturnPercent?.toFixed(2) + "%"}</div>
      </div>
    );
  });

  return <div className="table1">{pe != null && display}</div>;
}

function GreekHeader(indent?: boolean) {
  return (
    <div className="table1">
      {indent && <div className="c w5" />}
      {indent && <div className="c w90 ra" />}
      <div className="c w6 ra">Delta</div>
      <div className="c w6 ra">Gamma</div>
      <div className="c w6 ra">Rho</div>
      <div className="c w6 ra">Theta</div>
      <div className="c w6 ra">Vega</div>
    </div>
  );
}

export { GreekHeader };

function Greek(props: { data: BrokerPosition2; }) {
  var p = props.data;
  var delta = p.EQuote?.greeks?.delta ? p.EQuote.greeks.delta : 1; // if it is an equity, the delta is 100.

  var quantity = p.quantity ?? 1;

  return (
    <div className="table1">
      {typeof p.symbol != "undefined" && <div className="c w5">{p.symbol}</div>}
      {typeof p.longOrShort != "undefined" && (
        <div className="c w90 ra">
          {p.longOrShort} x {p.creditDebitSign * quantity}
        </div>
      )}
      <div className="c w6 ra">{(delta * quantity).toFixed(3)}</div>
      <div className="c w6 ra">{p.EQuote?.greeks && ((p.EQuote.greeks.gamma ?? 0) * quantity).toFixed(3)}</div>
      <div className="c w6 ra">{p.EQuote?.greeks && ((p.EQuote.greeks.rho ?? 0) * quantity).toFixed(3)}</div>
      <div className="c w6 ra">{p.EQuote?.greeks && ((p.EQuote.greeks.theta ?? 0) * quantity).toFixed(3)}</div>
      <div className="c w6 ra">{p.EQuote?.greeks && ((p.EQuote.greeks.vega ?? 0) * quantity).toFixed(3)}</div>
    </div>
  );
}

export { Greek };

function PositionClosingSummary(props: PositionSummaryProps) {
  const indent = () => {
    return (
      <div className="cnp table1 ra">
        <div className="c wSymbolOpt ra" />
        <div className="c w90 ra" />
        <div className="c w8 ra" />
        <div className="c w6" />
      </div>
    );
  };

  const shiftRow = (label: string, values: string[], classes: string, sym?: string) => {
    var tmpClasses = "c w90 ralign " + classes;
    var vals = values.map((v, i) => {
      return (
        <div className={tmpClasses} key={i}>
          {v}
        </div>
      );
    });

    return (
      <div className="table1 ">
        {indent()}
        <div className="c w7 ra" />
        <div className="c w90 ra">{label}</div>
        <div className="c w8 ra">{sym}</div>
        {vals}
      </div>
    );
  };

  var pe = props.data;
  var priceHeaders = pe.PriceHeaders();
  var openBal: string[] = [];
  var endBal: string[] = [];
  var change: string[] = [];
  var ret: string[] = [];
  var days: string[] = [];
  var apr: string[] = [];
  var margin: string[] = [];
  var marginCall: string[] = [];

  if (pe && priceHeaders && typeof pe.CurrentBalance != "undefined") {
    // currentbalance 0 is ok.
    priceHeaders.forEach((pc) => {
      pe.SetCurrentPriceType(pc.key);

      if (pc.key === "open") {
        endBal.push(pe.CurrentBalance.toFixed(2));
        change.push((pe.CurrentBalance - pe.OpenBalance).toFixed(2));
        ret.push(pe.ReturnPercent.toFixed(2));
        apr.push("");
      } else {
        endBal.push(pe.CurrentBalance.toFixed(2));
        change.push((pe.CurrentBalance - pe.OpenBalance).toFixed(2));
        ret.push(pe.ReturnPercent.toFixed(2));
        apr.push(pe.AnnualizedReturnPercent > 1000 ? ">1000%" : pe.AnnualizedReturnPercent.toFixed(2));
      }

      openBal.push(pe.OpenBalance.toFixed(2));
      days.push(pe.daysToExpiration?.toString() ?? "");
      var pem = pe.MarginRequirement;

      margin.push(pem.toFixed(2));
      marginCall.push(pe.MarginCall.toFixed(2));
    });
  }

  return pe != null ? (
    <div>
      {shiftRow("Open Bal", openBal, " ", "$")}
      {shiftRow("End Bal", endBal, "underline ", "$")}
      {shiftRow("MarginReq", margin, "underline", "$")}
      {shiftRow("CapBasis/Call", marginCall, "", "$")}
      {shiftRow("Change", change, "underline ", "$")}
      {shiftRow("Return", ret, "b", "%")}
      {shiftRow("Days Exp", days, "")}
      {shiftRow("APR", apr, "", "%")}
    </div>
  ) : (
    <div />
  );
}

function PositionClosingSummaryByColumn(props: { data: PositionEvaluator; }) {
  var pe = props.data;
  if (pe.PriceHeaders() && pe && typeof pe.CurrentBalance != "undefined") {
    var columns = [];

    // currentbalance 0 is ok.
    var i = 0;
    pe.PriceHeaders().forEach((pc) => {
      i++;
      pe.SetCurrentPriceType(pc.key);

      var apr = pe.AnnualizedReturnPercent > 1000 ? ">1000%" : pe.AnnualizedReturnPercent.toFixed(2);

      if (pc.key === "open") {
        columns.push(
          <div className="c" key={i}>
            <div>{pe.OpenBalance.toFixed(2)}</div>
            <div></div>
            <div>{pe.MarginRequirement.toFixed(2)}</div>
            <div>{pe.MarginCall.toFixed(2)}</div>
            <div>{(pe.CurrentBalance - pe.OpenBalance).toFixed(2)}</div>
            <div></div>
            <div>{pe.daysToExpiration}</div>
            <div></div>
          </div>
        );
      } else {
        columns.push(
          <div className="c" key={i}>
            <div>{pe.OpenBalance.toFixed(2)}</div>
            <div>{pe.CurrentBalance.toFixed(2)}</div>
            <div>{pe.MarginRequirement.toFixed(2)}</div>
            <div>{pe.MarginCall.toFixed(2)}</div>
            <div>{(pe.CurrentBalance - pe.OpenBalance).toFixed(2)}</div>
            <div>{pe.ReturnPercent.toFixed(2)}</div>
            <div>{pe.daysToExpiration}</div>
            <div>{apr}</div>
          </div>
        );
      }
    });
  }

  return (
    <div className="cnp table1 ra">
      <div className="c">
        <div>Open Bal</div>
        <div>End Bal</div>
        <div>MarginReq</div>
        <div>CapBasis/Call</div>
        <div>Change</div>
        <div>Return</div>
        <div>Days Exp</div>
        <div>APR</div>
      </div>
      {columns}
    </div>
  );
}

interface PositionValueProps {
  priceHeaders: PriceHeader[],
  data: BrokerPosition2;
}

function PositionCompact(props: PositionValueProps) {
  var bp = props.data;

  return bp != null ? (
    <div className="table1" title={"Quoted " + DateFuncs.DateTime(bp.quoteDate)}>
      <div className="c wSymbolOpt bgc">{bp.symbol}</div>
      <div className="c w90 ralign">
        {bp.longOrShort} x {bp.creditDebitSign * (bp.quantity ?? 0)}
      </div>
      <div className="c w7">{bp.isOption ? bp.optionChain?.optionType : ""} </div>
      <div className="c w90 bgc ralign">{bp.isOption ? "@ " + bp.optionChain?.strikePrice : ""} </div>

      <div className="c w8 ralign">$</div>

      <div className="c w6 ralign ">{bp.openPrice.toFixed(2)}</div>

      <div className="c w1 bgc">{bp.isOption ? DateFuncs.ShortDate(bp.expirationDate) : ""}</div>
    </div>
  ) : (
    <div />
  );
}

function PositionValue(props: PositionValueProps) {
  var bp = props.data;

  var displayChangesMarketValue = [<div />];

  if (props.priceHeaders && props.priceHeaders.length > 0) {
    displayChangesMarketValue = props.priceHeaders.map((pc, i) => {
      var c = "c w90 ralign " + (i % 2 ? "" : "bgc2");

      return (
        <div key={i} className={c}>
          {bp.PositionValue(pc.key, pc.key === "open")?.toFixed(2)}
        </div>
      );

      // create quotes and add.
    });
  }

  return bp ? (
    <div className="table1" title={"Quoted " + DateFuncs.DateTime(bp.quoteDate)}>
      <div className="c wSymbolOpt bgc">{bp.symbol}</div>
      <div className="c w90 ralign">
        {bp.longOrShort} x {bp.creditDebitSign * (bp.quantity ?? 0)}
      </div>
      <div className="c w7">{bp.isOption ? bp.optionChain?.optionType : ""} </div>
      <div className="c w90 bgc ralign">{bp.isOption ? "@ " + bp.optionChain?.strikePrice : ""} </div>

      <div className="c w8 ralign">$</div>

      <div className="c w6 ralign ">{bp.openPrice.toFixed(2)}</div>

      <div className="c w8 ralign">$</div>

      {displayChangesMarketValue}
      <div className="c w1 bgc">{bp.isOption ? DateFuncs.ShortDate(bp.expirationDate) : ""}</div>
    </div>
  ) : (
    <div />
  );
}

interface PositionHeaderProps {
  priceHeaders: PriceHeader[],
  short?: boolean;
}

function PositionHeader(props: PositionHeaderProps) {
  var displayChangesHeader: JSX.Element[] = [<div key="0" />];

  if (props.priceHeaders && props.priceHeaders.length > 0) {
    displayChangesHeader = props.priceHeaders.map((ph: any, i: number) => {
      var c = "c w90 ralign ";
      return (
        <div key={i} className={c}>
          <div>{ph.display}</div>
          <div>{ph.subtitle}</div>
        </div>
      );
    });
  }

  return (
    <div className="table1 ">
      <div className="c wSymbolOpt ">Symbol</div>
      <div className="c w90 ralign">Qty</div>
      <div className="c w7" />
      <div className="c w90 ralign">Strike</div>
      <div className="c w8 ralign " />
      <div className="c w6 ralign ">Open Price</div>
      {!props.short && <div className="c w8 ralign " />}
      {!props.short && displayChangesHeader}
      <div className="c w1 ">Expiration</div>
    </div>
  );
}

export const exportForTesting = {
  PositionValue,
  PositionClosingSummary,
};
