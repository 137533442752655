import React, { useState, useEffect } from "react";

import DateFuncs from "../../util/dateFuncs";
import Watches from "../../options/watches";

function OptionSelection(props) {
  const [expirationDate, setExpirationDate] = useState();
  const [showPins, setShowPins] = useState();
  const [showOpenInterest, setShowOpenInterest] = useState(false);
  const [showBreakeven, setShowBreakeven] = useState(true);
  const [showBreakevenPrice, setShowBreakevenPrice] = useState(true);
  const [showBidAsk, setShowBidAsk] = useState(true);
  const [showStrikePercent, setShowStrikePercent] = useState(true);

  const changeExpirationDate = (e) => {
    setExpirationDate(e.target.value);
  };

  const selectOption = (option, underlier, bid, qty) => {
    if (props.onOptionSelected) {
      props.onOptionSelected(option, underlier, bid, qty);
    }
  };

  const onPositionHover = (eq, pos) => {
    if (props.onPositionHover) {
      var p = {
        EQuote: eq,
        CreditDebitSign: pos,
        Quantity: 1,
      };

      props.onPositionHover(p);
    }
  };

  const eqSummary = (eq, expirationDate) => {
    return (
      <div>
        <div className="table1">
          <div className="c w75"></div>
          <div className="c w75">Change</div>
          <div className="c w75">Bid</div>
          <div className="c w75">Ask</div>
          <div className="c w75">Expiration</div>
        </div>
        <div className="table1">
          <div className="c w75 b ralign"> {eq.symbol} </div>
          <div className="c w75 ralign"> {eq.chgClosePct.toFixed(2)}%</div>
          <div className="c w75  ralign hover" onClick={() => selectOption(eq, eq, "bid", 100)}>
            {" "}
            {eq.bid.toFixed(2)}{" "}
          </div>
          <div className="c w75 bgc ralign hover " onClick={() => selectOption(eq, eq, "ask", 100)}>
            {" "}
            {eq.ask.toFixed(2)}{" "}
          </div>
          <div className="c w75 "> {expirationDate} </div>
        </div>
      </div>
    );
  };

  var priceData = props.data;

  var pinHeader = (
    <div className="table1">
      {showOpenInterest && <div className="c w75 ralign ">Open Interest</div>}
      {showBreakeven && <div className="c w75 ralign">Breakeven %change</div>}
      {showBreakevenPrice && <div className="c w75 ralign">Breakeven $</div>}
      {showBidAsk ? <div className="c w75 ralign ">Call Bid</div> : <div className="c w75 ralign ">Call Last</div>}
      {showBidAsk ? <div className="c w75 ralign ">Ask</div> : <div className="cnp"></div>}
      <div className="c w75 ralign">Strike</div>
      {showStrikePercent && <div className="c w75 ralign">% change</div>}
      {showBidAsk ? <div className="c w75 ralign ">Put Bid</div> : <div className="c w75 ralign ">Put Last</div>}
      {showBidAsk ? <div className="c w75 ralign ">Ask</div> : <div className="cnp"></div>}
      {showBreakevenPrice && <div className="c w75 ralign">Breakeven $</div>}
      {showBreakeven && <div className="c w75 ralign">Breakeven %change</div>}
      {showOpenInterest && <div className="c w75 ralign ">Open Interest</div>}
      {showPins && <div className="c w75 ralign ">Delta Total</div>}
      {showPins && <div className="c w75 ralign ">OTM Delta Total</div>}
    </div>
  );

  var title = "";

  if (Watches.ValidatePinData(priceData)) {
    // create a dropdown of exps

    var expDdl = (
      <select defaultValue={priceData.data[0].expiraitonDate} onChange={changeExpirationDate.bind(this)}>
        {priceData.data.map((d, i) => {
          var days = DateFuncs.TotalDays(DateFuncs.Timespan(new Date(d.expirationDate), new Date())) + 1;
          return (
            <option key={i} value={d.expirationDate}>
              {DateFuncs.ShortDateWDay(new Date(d.expirationDate))} {days} dte
            </option>
          );
        })}
      </select>
    );

    var exp = expirationDate != null ? expirationDate : priceData.data[0].expirationDate;

    var data = null;

    for (var i = 0; i < priceData.data.length; i++) {
      if (priceData.data[i].expirationDate === exp) {
        data = priceData.data[i];
        break;
      }
    }

    var eq = data?.equityQuote;

    if (eq) {
      title = eqSummary(eq, expDdl);
      var currentPriceData = data.optionQuotes;
      var strikes = [];

      currentPriceData.forEach((d) => {
        var s = d.optionChain.strikePrice;
        if (!strikes.includes(s)) {
          strikes.push(s);
        }
      });

      let deltaTotal = 0;
      let otmDeltaTotal = 0;

      const isItm = (optData, equity) => {
        if (optData.optionChain.optionType === "CALL") {
          return optData.optionChain.strikePrice < equity.lastTrade;
        } else {
          return optData.optionChain.strikePrice > equity.lastTrade;
        }
      };

      const getBreakeven = (underlierQuote, optPrice) => {
        return optPrice.optionChain.optionType === "CALL"
          ? ((optPrice.optionChain.strikePrice + optPrice.ask - underlierQuote.aBMidPrice) / underlierQuote.aBMidPrice) * 100 // call ITM
          : ((optPrice.optionChain.strikePrice - optPrice.ask - underlierQuote.aBMidPrice) / underlierQuote.aBMidPrice) * 100; // put ITM
      };

      const getBreakevenPrice = (underlierQuote, optPrice) => {
        return optPrice.optionChain.optionType === "CALL"
          ? optPrice.optionChain.strikePrice + optPrice.ask // call ITM
          : optPrice.optionChain.strikePrice - optPrice.ask; // put ITM
      };

      var grid = strikes.map((s, i) => {
        var callData = null;
        var callDatas = currentPriceData.filter((p) => p.optionChain.strikePrice === s && p.optionChain.optionType === "CALL");
        if (callDatas != null && callDatas.length > 0) {
          callData = callDatas.shift();
        }

        var putData = null;
        var putDatas = currentPriceData.filter((p) => p.optionChain.strikePrice === s && p.optionChain.optionType === "PUT");
        if (putDatas != null && putDatas.length > 0) {
          putData = putDatas.shift();
        }

        let callDelta = callData.greeks.delta * callData.openInterest * 100;
        let putDelta = putData.greeks.delta * putData.openInterest * 100;

        let delta = callDelta + putDelta;
        deltaTotal += delta;

        let otmDelta = 0;
        if (!isItm(callData, eq)) {
          otmDeltaTotal += callDelta;
          otmDelta += callDelta;
        }
        if (!isItm(putData, eq)) {
          otmDeltaTotal += putDelta;
          otmDelta += putDelta;
        }

        return (
          <div key={i} className=" table1">
            <div className=" table1 cnp">
              {showOpenInterest && <div className="c w75 ralign bgc2"> {callData.openInterest} </div>}
              {showBreakeven && <div className="c w75 ralign bgc2"> {getBreakeven(eq, callData).toFixed(2)} % </div>}
              {showBreakevenPrice && <div className="c w75 ralign bgc2"> {getBreakevenPrice(eq, callData).toFixed(2)} </div>}
              {showBidAsk ? (
                <div className="table1 cnp">
                  <div
                    onMouseOver={() => onPositionHover(callData, -1)}
                    onClick={() => selectOption(callData, eq, "bid", 100)}
                    className={"c w75 ralign hover" + (isItm(callData, eq) ? " bgc3" : "")}
                  >
                    {callData.bid.toFixed(2)}
                  </div>
                  <div
                    onMouseOver={() => onPositionHover(callData, 1)}
                    onClick={() => selectOption(callData, eq, "ask", 100)}
                    className={"c w75 ralign hover" + (isItm(callData, eq) ? " bgc3" : "")}
                  >
                    {callData.ask.toFixed(2)}
                  </div>
                </div>
              ) : (
                <div
                  onMouseOver={() => onPositionHover(callData, 1)}
                  onClick={() => selectOption(callData, eq, 100)}
                  className={"c w75 ralign hover " + (isItm(callData, eq) ? " bgc3" : "")}
                  title={callData.greeks.delta}
                >
                  {callData.lastTrade.toFixed(2)}
                </div>
              )}
            </div>

            <div className="c w75 b ralign bgc2" title={(((callData.optionChain.strikePrice - eq.lastTrade) / eq.lastTrade) * 100).toFixed(2) + "%"}>
              {" "}
              {callData.optionChain.strikePrice.toFixed(2)}{" "}
            </div>

            {showStrikePercent && (
              <div className="c w75 ralign bgc2"> {(((callData.optionChain.strikePrice - eq.lastTrade) / eq.lastTrade) * 100).toFixed(2)} %</div>
            )}

            <div className=" table1 cnp">
              {showBidAsk ? (
                <div className="table1 cnp">
                  <div
                    onMouseOver={() => onPositionHover(putData, -1)}
                    onClick={() => selectOption(putData, eq, "bid", 100)}
                    className={"c w75 ralign hover" + (isItm(putData, eq) ? " bgc3" : "")}
                  >
                    {putData.bid.toFixed(2)}
                  </div>
                  <div
                    onMouseOver={() => onPositionHover(putData, 1)}
                    onClick={() => selectOption(putData, eq, "ask", 100)}
                    className={"c w75 ralign hover" + (isItm(putData, eq) ? " bgc3" : "")}
                  >
                    {putData.ask.toFixed(2)}
                  </div>
                </div>
              ) : (
                <div
                  onMouseOver={() => onPositionHover(putData, 1)}
                  onClick={() => selectOption(putData, eq, 100)}
                  className={"c w75 ralign hover " + (isItm(putData, eq) ? " bgc3" : "")}
                  title={putData.greeks.delta}
                >
                  {putData.lastTrade.toFixed(2)}
                </div>
              )}

              {showBreakevenPrice && <div className="c w75 ralign bgc2"> {getBreakevenPrice(eq, putData).toFixed(2)} </div>}
              {showBreakeven && <div className="c w75 ralign bgc2"> {getBreakeven(eq, putData).toFixed(2)} % </div>}
              {showOpenInterest && <div className="c w75 ralign bgc2"> {putData.openInterest} </div>}
            </div>
            {showPins && <div className="c w75 ralign "> {delta.toFixed(0)} </div>}
            {showPins && <div className="c w75 ralign "> {otmDelta.toFixed(0)} </div>}
          </div>
        );
      });

      //var summary = null;
      var summary = (
        <div>
          <div>&nbsp;</div>
          <div className="table1">
            <div className="c w2">Delta total:</div>
            <div className="c w2 ralign"> {deltaTotal.toFixed(0)}</div>
          </div>{" "}
          <div className="table1">
            <div className="c w2">Unhedging Max: </div>
            <div className="c w2 ralign">$ {(deltaTotal * eq.lastTrade).toFixed(0)}</div>
          </div>
          <div className="table1">
            <div className="c w2">OTM Delta Total: </div>
            <div className="c w2 ralign">{otmDeltaTotal.toFixed(0)}</div>
          </div>
        </div>
      );

      /*
            var summary = <div>
                <div>&nbsp;</div>
                <div className="table1">
                    <div className="c w2">Delta total:</div><div className="c w2 ralign"> {deltaTotal.toFixed(0)}</div>
                </div> <div className="table1">
                    <div className="c w2">Avg 1 day vol: </div><div className="c w2 ralign">{(eq.tickerData.brokerObject.fundamental.vol1DayAvg).toFixed(0)}</div>
                </div> <div className="table1">
                    <div className="c w2">D% of 1 day vol: </div><div className="c w2 ralign">{((deltaTotal / eq.tickerData.brokerObject.fundamental.vol1DayAvg) * 100).toFixed(2)} %</div>
                </div> <div className="table1">
                    <div className="c w2">Unhedging Max: </div><div className="c w2 ralign">$ {(deltaTotal * eq.lastTrade).toFixed(0)}</div>
                </div> <div className="table1">
                    <div className="c w2">Market Cap: </div><div className="c w2 ralign">$ {eq.tickerData.brokerObject.fundamental.marketCap * 1000000}</div>
                </div> <div className="table1">
                    <div className="c w2">Delta Percent: </div><div className="c w2 ralign">{(((deltaTotal * eq.lastTrade) / (eq.tickerData.brokerObject.fundamental.marketCap * 1000000)) * 100).toFixed(2)} %</div>
                </div>
            </div>;
            */
    }

    //<input type="checkbox" value={showBidAsk} onChange={(e) => setShowBidAsk(e.target.checked)} /> Show Bid/Ask <br />
    return (
      <div>
        <input type="checkbox" checked={showBreakeven} onChange={(e) => setShowBreakeven(e.target.checked)} /> Show Breakeven %<br />
        <input type="checkbox" checked={showBreakevenPrice} onChange={(e) => setShowBreakevenPrice(e.target.checked)} /> Show Breakeven $<br />
        <input type="checkbox" checked={showOpenInterest} onChange={(e) => setShowOpenInterest(e.target.checked)} /> Show Open Interest <br />
        <input type="checkbox" checked={showPins} onChange={(e) => setShowPins(e.target.checked)} /> Show Pins <br />
        <input type="checkbox" checked={showStrikePercent} onChange={(e) => setShowStrikePercent(e.target.checked)} /> Show Strike %<br />
        {showPins && summary}
        <div>&nbsp;</div>
        {grid != null && title}
        <div className="table1 ">
          <div className="cnp">
            {grid != null && pinHeader} {grid != null && grid}{" "}
          </div>
        </div>
      </div>
    );
  } else return <div />;
}

export default OptionSelection;
