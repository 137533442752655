import Settings from "../config/settings";
import { HttpUtil } from "../util/httpUtil";

export class Watches {
  static GetSymbol(auth, symbol) {
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/min/v1/symbol/" + symbol,
      },
      auth
    ).catch((error) => {
      console.log("Get Recent Symbols failed: ", error);
    });
  }

  static GetRecentTickers(auth) {
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/min/v1/symbols",
      },
      auth
    ).catch((error) => {
      console.log("Get Recent Symbols failed: ", error);
    });
  }

  static GetPinData(auth, tickerIds, fromDate, toDate) {
    if (typeof tickerIds == "undefined" || tickerIds == null || !Array.isArray(tickerIds)) {
      Promise.reject("tickerids null.");
    }

    return HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/min/v1/pindata",
        body: { tickerIds: tickerIds, fromDate: fromDate, toDate: toDate },
      },
      auth
    )
      .then((data) => {
        if (Watches.ValidatePinData(data)) {
          return data;
        }
      })
      .catch((error) => {
        console.log("Get Pin Data failed: ", error);
      });
  }

  static ValidatePinData(priceData) {
    return (
      typeof priceData != "undefined" &&
      priceData != null &&
      typeof priceData.data != "undefined" &&
      priceData.data != null &&
      Array.isArray(priceData.data) &&
      priceData.data.length > 0
    );
  }

  static GetPositionQuotes(auth, tickerIds, optionChainIds) {
    // must have token, callback, plus either tickers or opts
    if (!tickerIds || !Array.isArray(tickerIds) || !optionChainIds || !Array.isArray(optionChainIds)) {
      throw new Error("tickerids or optionchainids null.");
    }

    return HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/min/v1/quotes2",
        body: {
          tickerIds: tickerIds,
          optionChainIds: optionChainIds,
        },
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null && Array.isArray(data)) {
          return data;
        }
      })
      .catch((error) => {
        console.log("Get Position Quotes failed.");
        console.log(error);
      });
  }

  static GetPositionQuotes3(auth, symbols, optionChains) {
    // must have token, callback, plus either tickers or opts
    if (
      typeof symbols == "undefined" ||
      symbols == null ||
      !Array.isArray(symbols) ||
      typeof optionChains == "undefined" ||
      optionChains == null ||
      !Array.isArray(optionChains)
    ) {
      return Promise.reject("null parameter.");
    }

    return HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/min/v1/quotes3",
        body: { symbols: symbols, optionChains: optionChains },
      },
      auth
    )
      .then((data) => {
        if (!(data && Array.isArray(data))) {
          return Promise.reject("Position returned is null.");
        }

        return data;
      })
      .catch((error) => {
        console.log("Get Position Quotes 3 failed: ", error);
      });
  }

  GetWatches(auth) {
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/min/v1/watches",
      },
      auth
    )
      .then((data) => {
        if (!(data && Array.isArray(data))) {
          return Promise.reject("Position returned is null.");
        }
      })
      .then((data) => {
        var tickerIds = data.map((w, i) => {
          return w.tickerId;
        });

        return tickerIds;
      })
      .catch((error) => {
        console.log("Get Quotes failed: ", error);
      });
  }

  AddWatch(auth, watchSymbol) {
    HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/minoa/v1/watch/" + watchSymbol,
      },
      auth
    ).catch((error) => {
      console.log("Add Watch failed.");
      console.log(error);
    });
  }

  DeleteWatch(auth, watchId) {
    if (!watchId) {
      return;
    }

    HttpUtil.Fetch(
      {
        method: "DELETE",
        url: Settings.serviceHost + "/minoa/v1/watch/" + watchId,
      },
      auth
    ).catch((error) => {
      console.log("Delete Watch failed.");
      console.log(error);
    });
  }
}

export default Watches;
