import React, { Component } from "react";

class RevLight extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {
        // will need to pass authentication header down to the server side when requesting resources.
    }

    render() {
        return (
            <div className="RevLight">
                <div className="bodyHeader panelHeader">RevLight</div>
                <div className="bodyPanel">
                    
                </div>
            </div>
        );
    }
}

export default RevLight; 