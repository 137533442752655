import React, { useState } from "react";
import Builder from "./options/Builder";
import PositionManager from "./options/PositionManager";

function Options(props) {
  const [reloadTime, setReloadTime] = useState();

  const onUpdated = () => {
    setReloadTime(new Date());
  };

  return (
    <div className="Options ">
      <div className="underline">
        <Builder onUpdated={onUpdated} />
        <br />
      </div>
      <div className={"menuItem underline "}>Saved Positions</div>
      <PositionManager reloadTime={reloadTime} />
    </div>
  );
}

export default Options;
