import React from "react";
import logo from "../resources/GitHub-Mark/PNG/GitHub-Mark-64px.png";

function Github(props) {
  const redirectHome = () => {
    window.open("https://github.com/bradamelton/saberfin.io/issues", "_blank");
  };

  return (
        <div className={props.className} stype={{ margin: "10px 0px 0px 0px" }} onClick={redirectHome}>
          <img style={{ height: "38px" }} src={logo} alt="github issues link" />
        </div>
  );
}

export default Github;
