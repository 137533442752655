import React, { Component, useState, useEffect } from "react";
import Watches from "../options/watches";
import DateFuncs from "../util/dateFuncs";
import { useAuth } from "react-oidc-context";

const Tickers = (props) => {
  const [tickers, setTickers] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    var tickerHistoryJson = localStorage.getItem("tickerHistory");
    if (typeof tickerHistoryJson != "undefined" && tickerHistoryJson != null && tickerHistoryJson !== "undefined") {
      setTickers(JSON.parse(tickerHistoryJson));
    }
  }, []); // don't rerun.

  const searchTicker = (e) => {
    e.preventDefault();
    var t = e.target;
    if (t != null) {
      if (typeof t.symbol == "undefined" || t.symbol.value == null || t.symbol.value === "") {
        return;
      }

      // get the ticker id
      Watches.GetSymbol(auth, t.symbol.value).then((data) => onSymbol(data));

      t.symbol.value = "";
    }
  };

  const getLatestTickers = () => {
    if (tickers && tickers.length > 0) {
      return tickers.filter((t) => t);
    }
    return [];
  };

  const saveLatestTickers = (tickers) => {
    localStorage.setItem("tickerHistory", JSON.stringify(tickers));
  };

  const onSymbol = (ticker) => {
    if (ticker) {
      var tickers = getLatestTickers();
      clearAll(tickers, ticker, compare);
      tickers.unshift(ticker);
      saveLatestTickers(tickers);
      setTickers(tickers);
      selectTicker(ticker.id);
    }
  };

  const compare = (a, b) => {
    if (!(a && b)) {
      return false;
    }
    return a.id === b.id;
  };

  const loadTicker = (tickerId) => {
    Watches.GetPinData(auth, [tickerId], DateFuncs.ShortDate(new Date()), DateFuncs.AddDays(new Date(), 70), props.onAuthError).then((data) => {
      props.onTickerLoaded(data);
    });
  };

  const selectTicker = (tickerId) => {
    loadTicker(tickerId);

    // set it to the latest one since it has been clicked.
    var tickers = getLatestTickers();

    if (tickers) {
      var ticker = tickers.find((a) => {
        return a && a.id === tickerId;
      });
      if (ticker) {
        clearAll(tickers, ticker, compare);
        tickers.unshift(ticker);
        saveLatestTickers(tickers);
      }
    }
  };

  return <TickerList tickers={tickers} onSearchTicker={searchTicker} onSelectTicker={selectTicker} />;
};

function clearAll(array, obj, func) {
  // remove the previous ones.
  var firstIndex = indexOfFunc(array, obj, func);
  while (firstIndex > -1) {
    array.splice(firstIndex, 1);
    firstIndex = indexOfFunc(array, obj, func);
  }
}

function indexOfFunc(array, obj, func) {
  for (var i = 0; i < array.length; i++) {
    if (func(obj, array[i])) {
      return i;
    }
  }

  return -1;
}

class TickerList extends Component {
  constructor() {
    super();
    this.state = {
      tickers: [],
    };
  }

  componentDidMount() {}

  render() {
    var tickers = [];

    if (this.props.tickers && this.props.tickers.length > 0) {
      tickers = this.props.tickers.map((t, i) => {
        if (t) {
          return (
            <div key={i} className="button c w75" onClick={() => this.props.onSelectTicker(t.id)}>
              {t.name}
            </div>
          );
        }
        return <div key={i} />;
      });
    }

    return (
      <div className="twoCol ">
        <div className="c w6">
          <form onSubmit={this.props.onSearchTicker}>
            <div>Symbol:</div>
            <input className="w6" type="text" name="symbol" />
          </form>
        </div>

        <div className="c allowScroll table1 ">{tickers}</div>
      </div>
    );
  }
}

export default Tickers;
