import React, { Component } from "react";
import { Link } from "react-router-dom";

import ClickMe from "../util/clickMe";

function MenuLink(props) {
  return (
    <div className={props.className} onClick={(e) => ClickMe.clickMe(e, props.onClick)}>
      <Link to={props.to}>{props.children}</Link>
    </div>
  );
}

export function HrefLink(props) {
  return (
    <div className={props.className} onClick={(e) => ClickMe.clickMe(e, props.onClick)}>
      <a href={props.to}>{props.children}</a>
    </div>
  );
}

export default MenuLink;
