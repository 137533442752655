import React, { useContext, useEffect, useState } from "react";
import MenuLink, { HrefLink } from "./MenuLink";
import UserDashboard from "./UserDashboard";
import { useAuth } from "react-oidc-context";
import JSUtil from "../util/jsUtil";
import { UserContext } from "../usermodule/context/UserContext";

function HomePage(props) {
  const auth = useAuth();
  const userContext = useContext(UserContext);

  const login = () => {
    if (auth && !auth.user) {
      JSUtil.Log("calling signin popup from Home Page.", 2, "auth");
      auth.signinPopup().catch((error) => {
        console.log("SigninPopup from Home Page error:", error);
      });
    }
  };

  return (
    <div className="HomePage">
      {userContext.state.authenticated ? (
        <UserDashboard />
      ) : (
        <div>
          <div className="bodyHeader panelHeader">Welcome</div>
          <div className="bodyPanel withText">
            <div>
              SaberFin is a place for anyone to share and learn about Options!
              <br />
              <br />
              <MenuLink to={"/contact"} className="menuItem">
                Contact Us
              </MenuLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;
