import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PositionEvaluatorComponent from "./options/PositionEvaluator";
import PositionsHttp from "../options/positionsHttp";
import { PositionEvaluator } from "min-types/MinClasses";

function PublicPosition(props: any) {
  const [pe, setPe] = useState<PositionEvaluator>();
  const [isLoading, setIsLoading] = useState(true);
  const { positionId } = useParams();

  useEffect(() => {
    let isSubscribed = true;
    if (positionId && positionId.length > 0) {
      PositionsHttp.GetPositionPublic(positionId)
        .then((data) => {
          if (data != null) {
            if (isSubscribed) {
              setPe(data);
              setIsLoading(false);
            }
          }
        })
        .catch((error) => {
          if (isSubscribed) {
            setIsLoading(false);
          }
          console.log("Public position error: ", error);
        });
    }

    return () => { isSubscribed = false; };
  }, []);

  return (pe &&
    <div>
      {isLoading ? (
        <div>loading</div>
      ) : (
        <PositionEvaluatorComponent hideClear={true} hideSave={true} data={pe} showGreeks={true} />
      )}
    </div>
  );
}

export default PublicPosition;
