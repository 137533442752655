import Settings from "../config/settings.json";
import JSUtil from "../util/jsUtil";
import type { AuthContextProps } from "react-oidc-context";

//import { PositionEvaluator } from "../entities/MinClasses";

import { HttpUtil } from "../util/httpUtil";

import { PositionEvaluator } from "min-types/MinClasses";
import { MinTypeBuilders } from "min-types/minTypeBuilders";

export class PositionsHttp {
  static SavePositionEval(auth: AuthContextProps, position: PositionEvaluator): Promise<PositionEvaluator> {
    return HttpUtil.FetchAs<PositionEvaluator>(
      {
        url: Settings.serviceHost + "/saber/v1/position",
        body: position as any,
      } as Request,
      auth
    ).then((data) => {
      return MinTypeBuilders.buildPositionEvaluator(data).then((pe) => {
        return pe;
      });
    });
  }

  static GetPositions(auth: AuthContextProps): Promise<PositionEvaluator[]> {
    if (!auth || !auth.user) {
      return Promise.reject("Auth required.");
    }
    return HttpUtil.FetchAs<PositionEvaluator[]>(
      {
        method: "GET",
        url: Settings.serviceHost + "/saber/v1/positions",
      } as Request,
      auth
    ).then((data) => {
      return Promise.all(data?.map(async (d) => await MinTypeBuilders.buildPositionEvaluator(d)));
    });
  }

  static GetPositionsByBatch(auth: AuthContextProps, batchId: string): Promise<PositionEvaluator[]> {
    if (!auth || !auth.user) {
      return Promise.reject("Auth required.");
    }
    return HttpUtil.FetchAs<PositionEvaluator[]>(
      {
        method: "GET",
        url: Settings.serviceHost + "/saber/v1/positions?batchId=" + batchId,
      } as Request,
      auth
    ).then((data) => {
      // TODO: needs testing
      return Promise.all(data.map(async (d) => await MinTypeBuilders.buildPositionEvaluator(d)));
    });
  }

  static GetPositionPublic(positionId: string): Promise<PositionEvaluator> {
    return HttpUtil.FetchAs<PositionEvaluator>({
      method: "GET",
      url: Settings.serviceHost + "/saber/v1/positionPublic?id=" + positionId,
    } as Request).then((data) => {
      return MinTypeBuilders.buildPositionEvaluator(data).then((pe) => {
        return pe;
      });
    });
  }

  static GetPosition(auth: AuthContextProps, positionId: string): Promise<PositionEvaluator> {
    var i = 1;
    return HttpUtil.FetchAs<PositionEvaluator>(
      {
        method: "GET",
        url: Settings.serviceHost + "/saber/v1/position?id=" + positionId,
      } as Request,
      auth
    ).then((data) => {
      return MinTypeBuilders.buildPositionEvaluator(data).then((pe) => {
        return pe;
      });
    });
  }

  static DeletePosition(auth: AuthContextProps, positionId: string): Promise<any> {
    return HttpUtil.Fetch(
      {
        method: "DELETE",
        url: Settings.serviceHost + "/saber/v1/position?id=" + positionId,
      } as Request,
      auth
    );
  }
}

export default PositionsHttp;
