import React, { Component } from "react";

class Contact extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="Contact">
                <div className="bodyHeader panelHeader">Contact Us</div>
                <div className="bodyPanel">
                    <ul>
                        <li> Creator
                            <ul>
                                <li>
                                    <a href="mailto://sales@saberfin.io">sales@saberfin.io</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}


export default Contact; 