import React, { useState, useEffect } from "react";
import PositionEvaluatorComponent, { exportForTesting as exportForTestingNew } from "./options/PositionEvaluator";

import { MinTypeBuilders } from "min-types/minTypeBuilders";
import { BrokerPosition2, PositionEvaluator, PriceHeader } from "min-types/MinClasses";

import pe2 from "./../test_data/pe2.json";

function PETest(props: {}) {
    const [peData, setPeData] = useState<PositionEvaluator>();
    useEffect(() => {
        MinTypeBuilders.buildPositionEvaluator(pe2).then((pe) => {
            console.log("setting data pe: ", pe);
            setPeData(pe);
        });
    }, []);

    var pec = <div />;

    if (peData) {
        pec = <PositionEvaluatorComponent key={0}
            index={1}
            data={peData}
            onClearPositions={(index?: number): any => { }}
            showGreeks={true}
            displayChanges={[-0.05, -0.025, -0.01, 0, 0.01, 0.025, 0.05]}
            onUpdated={(): any => { }} />;
    }

    return <div>
        {pec}
    </div>;
}

export default PETest;