import React from "react";
import Options from "./Options";

function UserDashboard(props) {
  // pick a default service. We want Options Research.
  return (
    <div className="UserDashboard">
      <Options />
    </div>
  );
}

export default UserDashboard;
