import { Guid } from "guid-typescript";

export class User {
    id?: Guid = Guid.create();
    exists?: boolean = false;
    authenticated: boolean = false;
    name?: string = "";
    authenticationType?: string = "none";
    email?: string = "";
    firstName?: string = "";
    lastName?: string = "";
    refreshToken?: string = "";
    lastLogin?: string = "";
    lastAccess?: string = "";
    expires?: string = "";
    id_token?: string = "";
    services?: Services = new Services({});
    disabled?: boolean = true;
    passwordHash?: string = "";
    emailVerified?: boolean = false;
    createDate?: string = "";
    unsubscribeFromEmails?: boolean = false;
    unsubscribedDate?: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}

export interface IUser {
    id?: Guid;
    exists?: boolean;
    authenticated: boolean;
    name?: string;
    authenticationType?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    refreshToken?: string;
    lastLogin?: string;
    lastAccess?: string;
    expires?: string;
    id_token?: string;
    services?: Services;
    disabled?: boolean;
    passwordHash?: string;
    emailVerified?: boolean;
    createDate?: string;
    unsubscribeFromEmails?: boolean;
    unsubscribedDate?: string;
}

export interface IUserAction {
    action: UserActionType,
    payload?: IUser | null;
}

export enum UserActionType {
    LogIn,
    LogOut,
    SetUser
}

export class Services {
    hasRevLight?: boolean = false;
    hasOptions?: boolean = false;
    hasAdmin?: boolean = false;
    hasReports?: boolean = false;

    constructor(obj: IServices) {
        Object.assign(this, obj);
    }
}
export interface IServices {
    hasRevLight?: boolean;
    hasOptions?: boolean;
    hasAdmin?: boolean;
    hasReports?: boolean;

}