export class ClickMe {
    static clickMe = (e, onClick) => {
        if (e.target === e.currentTarget) {
            e.stopPropagation();
            e.target.children[0].click();
        }
        else {
            if (typeof (onClick) != "undefined" && onClick != null) { onClick(); }
        }
    }
}

export default ClickMe;