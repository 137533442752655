import React, { useState } from "react";
import Tickers from "../Tickers";
import { Greek, GreekHeader } from "./PositionEvaluator";
import OptionSelection from "./OptionSelection";

function OptionList(props) {
  var [displayGreek, setDisplayGreek] = useState();
  var [pinData, setPinData] = useState();

  const onTickerLoaded = (data) => {
    setPinData(data);
    if (props.onTickerSelected) {
      props.onTickerSelected(data);
    }
  };

  const onPositionHover = (eq) => {
    setDisplayGreek(eq);
  };

  return (
    <div>
      <Tickers onTickerLoaded={onTickerLoaded} />
      <div>&nbsp;</div>
      {pinData && <OptionSelection onPositionHover={onPositionHover} data={pinData} onOptionSelected={props.onOptionSelected} />}
      <div>&nbsp;</div>
      {displayGreek && (
        <div>
          {GreekHeader()} <Greek data={displayGreek} />
        </div>
      )}
    </div>
  );
}

export default OptionList;
