import Settings from "../config/settings";
import { HttpUtil } from "../util/httpUtil";

export class AdminUser {
  static DeleteUser(user, auth) {
    HttpUtil.Fetch(
      {
        method: "DELETE",
        url: Settings.serviceHost + "/minoa/v1/user",
        body: user,
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null) {
          console.log("user delete successful.");
        }
      })
      .catch((error) => {
        console.log("Delete user failed.");
        console.log(error);
      });
  }

  static PostUser(user, auth) {
    return HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/minoa/v1/user",
        body: user,
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null) {
          return data;
        }
      })
      .catch((error) => {
        console.log("Post User failed.", error);
        Promise.reject(error);
      });
  }

  static GetUsers(auth) {
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/minoa/v1/users",
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null) {
          return data;
        }
      })
      .catch((error) => {
        console.log("error getting users: ", error);
        Promise.reject(error);
      });
  }

  static GenerateAPIKey(auth) {
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/auth/v1/apiKey",
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null) {
          return data;
        }
      })
      .catch((error) => {
        console.log("error generating api key: ", error);
        Promise.reject(error);
      });
  }
}
