import React, { useState } from "react";

import OptionList from "./OptionList";
import PositionEvaluatorComponent from "./PositionEvaluator";

import { PositionEvaluator, EQuote } from "min-types/MinClasses";
import { MinTypeBuilders } from "min-types/minTypeBuilders";

function Builder(props: any): any {
  var [showOptionList, setShowOptionList] = useState(true);
  var [positionEvals, setPositionEvals] = useState<PositionEvaluator[]>([]);
  var [currentPEIndex, setCurrentPEIndex] = useState(0);
  var [tickerHasBeenSelected, setTickerHasBeenSelected] = useState(false);

  const addPosition = async (eq: EQuote, underlier: EQuote, bidAsk: string, qty: number) => {
    var index = currentPEIndex;
    var equote = await MinTypeBuilders.buildEQuote(eq);
    // add the position to the positions
    var peNew = null;

    if (positionEvals.length > index) {
      peNew = positionEvals[index];
    } else {
      peNew = await MinTypeBuilders.buildPositionEvaluator(new PositionEvaluator());
      peNew.description = underlier.symbol;
      positionEvals.unshift(peNew);
      index = 0;
    }

    var ul = await MinTypeBuilders.buildEQuote(underlier);
    // this will be if they pick bid or ask in the chart when it shows that..
    var positionDefinition = equote.GetPositionDefinition(qty, bidAsk === "bid" ? "Short" : "Long", ul);
    peNew.AddPosition(positionDefinition, equote);

    setPositionEvals([...positionEvals]);
    setCurrentPEIndex(index);
  };

  const clearPositions = (index: number) => {
    var pes = positionEvals;
    pes.splice(index, 1);
    setPositionEvals([...pes]);
    setCurrentPEIndex(pes.length);
  };

  const toggleOptionList = () => {
    setShowOptionList(!showOptionList);
  };

  const addNewPositionEnd = () => {
    setCurrentPEIndex(positionEvals.length);
  };

  const tickerSelected = () => {
    setTickerHasBeenSelected(true);
  };

  var positions = null;

  if (positionEvals && positionEvals.length > 0) {
    positions = positionEvals.map((pe, i): any => {
      return (
        pe && (
          <PositionEvaluatorComponent
            key={i}
            index={i}
            data={pe}
            onClearPositions={clearPositions}
            showGreeks={true}
            displayChanges={[-0.05, -0.025, -0.01, 0, 0.01, 0.025, 0.05]}
            onUpdated={props.onUpdated}
          />
        )
      );
    });
  }

  return (
    <div>
      {showOptionList && <OptionList onOptionSelected={addPosition} onTickerSelected={tickerSelected} />}
      {positions && positions.length > 0 ? (
        <div className="button" onClick={addNewPositionEnd}>
          New Position
        </div>
      ) : !tickerHasBeenSelected ? (
        <div>Please enter or select a Ticker Symbol</div>
      ) : (
        <div>Click a Bid or Ask to start a Postion</div>
      )}
      {positions}
    </div>
  );
}

export default Builder;