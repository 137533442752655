import React, { useState, useEffect } from "react";
import PositionEvaluator from "./PositionEvaluator";
import { Distl } from "../../options/distl";
import { useAuth } from "react-oidc-context";

function Positions2(props) {
  const [collapsed, setCollapsed] = useState(true);
  const [data, setData] = useState(null);
  const auth = useAuth();

  const toggleCollapsed = (id) => {
    console.log("setting collapsed:", !collapsed);
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    console.log("props", props.data, "data", data);
    if (props.data && props.data.Id != data?.Id) {
      //setData(props.data);
      console.log("reset data.");
      setData(null);
      setCollapsed(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (!collapsed && !data) {
      console.log("not collapsed. Getting data.");
      Distl.GetPE(auth, props.data.Id).then((data) => {
        setData(data);
      });
    } else {
      console.log("collapsed or have data. Waiting.", data);
    }
  }, [collapsed]);

  return (
    <div>
      {props.canClear && (
        <div
          className="hover"
          onClick={() => {
            if (props.clearPositions) {
              props.clearPositions();
            }
          }}
        >
          Clear
        </div>
      )}

      <div className="table1 hover" onClick={() => toggleCollapsed(data?.Id)}>
        <div className="c w5 ra bgc2">{props.data.DisplayName}</div>
        <div className="c w150 ra b">{props.data.Description}</div>
      </div>
      {data && !collapsed && <PositionEvaluator data={data} user={props.user} includeOpen={props.includeOpen} />}
    </div>
  );
}

export default Positions2;
