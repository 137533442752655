import Settings from "../config/settings";
import CryptoJS from "crypto-js";
import { HttpUtil } from "../util/httpUtil";

export class User {
  static GetUser(auth) {
    return HttpUtil.Fetch(
      {
        method: "GET",
        url: Settings.serviceHost + "/minoa/v1/user",
      },
      auth
    )
      .then((user) => {
        if (user && user.authenticated) {
          return user;
        } else {
          console.log("User not authenticated.5 Server returned: " + user);
          Promise.reject("User not authenticated.");
        }
      });
  }

  static UpdatePassword(user, password, auth) {
    var hash = CryptoJS.SHA256(password);
    user.passwordHash = hash.toString(CryptoJS.enc.Base64);

    return HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/minoa/v1/updatePassword",
        body: user,
      },
      auth
    );
  }

  static SignInUp(email, password, auth) {
    var hash = CryptoJS.SHA256(password);
    var jsonBody = {
      email: email,
      passwordHash: hash.toString(CryptoJS.enc.Base64),
    };

    return HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/minoa/v1/user",
        body: jsonBody,
      },
      auth
    );
  }
}

export default User;
