import Settings from "../config/settings";
import { HttpUtil } from "../util/httpUtil";

export class AdminEmail {
  static SendInvite(toEmail, email, subject, auth) {
    HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/auth/v1/sendInvite",
        body: { toEmail: toEmail, email: email, subject: subject },
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null) {
        } else {
          console.log("error from send email: Server returned: ");
          console.log(data);
        }
      })
      .catch((error) => {
        console.log("error from send email: ", error);
      });
  }

  static SendEmail(email, subject, body, auth) {
    HttpUtil.Fetch(
      {
        url: Settings.serviceHost + "/util/v1/email",
        body: { email: email, subject: subject, body: body },
      },
      auth
    )
      .then((data) => {
        if (typeof data != "undefined" && data != null) {
        } else {
          console.log("error from send email: Server returned: ");
          console.log(data);
        }
      })
      .catch((error) => {
        console.log("error from send email: " + error);
      });
  }
}
