import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Link, useLocation, useParams } from "react-router-dom";

import "./App.css";
import "./Menu.css";

import Contact from "./components/Contact";
import RevLight from "./components/RevLight";
import Options from "./components/Options";
import Admin from "./components/Admin";
import Logo from "./components/Logo";
import MenuLink from "./components/MenuLink";
import AuthHandler from "./usermodule/components/AuthHandler";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import Settings from "./config/settings.json";
import HomePage from "./components/HomePage";
import PublicPosition from "./components/PublicPosition";
import PETest from "./components/PETest";

import { UserProvider, UserContext } from "./usermodule/context/UserContext";
import { User } from "./usermodule/entities/User";
import JSUtil from "./util/jsUtil";
import Github from "./components/Github";

import AccountInfo from "./usermodule/components/AccountInfo";
import Report2 from "./components/options/Report2";
import Report5 from "./components/options/Report5";

function App() {
  const queryParams = useParams();

  // if there is a url, can we add it here to redirect once we get back?
  // can't... need to use state params:
  // https://stackoverflow.com/questions/7722062/google-oauth-2-0-redirect-uri-with-several-parameters

  const config = {
    ...Settings.oidcConfig,
    redirect_uri: Settings.hostName + "/",
    onSigninCallback: () => {
      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  };

  return (
    <AuthProvider {...config} >
      <UserProvider value={new User({ authenticated: false })}>
        <Home />
      </UserProvider>
    </AuthProvider >
  );
}

function Home() {
  const [menuOpen, setMenuOpen] = useState(false);
  const userContext = useContext(UserContext);

  JSUtil.Log("loading home: authenticated? " + userContext?.state.authenticated, 2, "components");

  const closeMenu = () => {
    if (menuOpen === true) {
      setMenuOpen(false);
    }
  };

  const svcs = userContext?.state.services || {};
  const menuClass = "menuItem " + (menuOpen && "show");

  return (
    <div className="App flexContainer flexColumn fullHeight main">
      <nav className="flexContainer blueBackground">
        <div className="nav flexItem " onClick={closeMenu}>
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className="nav flexContainer ">
          {svcs.hasOptions ? (
            <MenuLink className={menuClass} to={"/options"} onClick={closeMenu}>
              Options Research
            </MenuLink>
          ) : (
            ""
          )}
          {svcs.hasReports ? (
            <MenuLink className={menuClass} to={"/reports"} onClick={closeMenu}>
              Reports
            </MenuLink>
          ) : (
            ""
          )}
          <MenuLink className={menuClass} to={"/contact"} onClick={closeMenu}>
            Contact
          </MenuLink>

          {svcs.hasRevLight ? (
            <MenuLink className={menuClass} to={"/revLight"} onClick={closeMenu}>
              RevLight
            </MenuLink>
          ) : (
            ""
          )}
          {svcs.hasAdmin ? (
            <MenuLink className={menuClass} to={"/admin"} onClick={closeMenu}>
              Administration
            </MenuLink>
          ) : (
            ""
          )}
          <Github className={menuClass} />
          <AuthHandler dynClass={menuClass} />
        </div>
        {menuOpen ? (
          <div className="menuClose menuButton mobileOnly" onClick={closeMenu}></div>
        ) : (
          <div className="menuOpen menuButton mobileOnly" onClick={() => setMenuOpen(true)}></div>
        )}
      </nav>
      <MainRoutes />
    </div>
  );
}

function MainRoutes() {
  return (
    <div className="content ">
      <Routes>
        <Route path="/contact" element={<Contact />} />
        <Route path="/revlight" element={<RevLight />} />
        <Route path="/options" element={<Options />} />
        <Route path="/reports" element={<Report2 />} />
        <Route path="/reports-test" element={<Report5 />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/p/:positionId" element={<PublicPosition />} />
        <Route path="/user" element={<AccountInfo />} />
        <Route path="/test" element={<PETest />} />
        <Route path="*" element={< LoginComplete />} />
      </Routes>
    </div>
  );
}

function LoginComplete() {
  console.log("Login complete.");
  return <div>Login Complete.</div>;
}

export default App;
