
import React, { useState, useEffect, useMemo, useCallback, Component, ComponentProps } from "react";

import { useAuth } from "react-oidc-context";
import { Distl } from "../../options/distl";
import { PositionsHttp } from "../../options/positionsHttp";
import { PositionEvaluator } from "min-types/MinClasses";
import PositionEvaluatorComponent from "./PositionEvaluator";

import DateFuncs from "../../util/dateFuncs";

import { Column, Table, useReactTable, ColumnFiltersState, getCoreRowModel, getFilteredRowModel, getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, getPaginationRowModel, sortingFns, getSortedRowModel, FilterFn, SortingFn, ColumnDef, flexRender, FilterFns, } from '@tanstack/react-table';

import { RankingInfo, rankItem, compareItems, } from '@tanstack/match-sorter-utils';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

class ColumnSelectorProps {
  columns: any;
  onChange: ((() => any) | null) = null;
}

function ColumnSelector(props: ColumnSelectorProps) {

// make checkboxes for all and return json for the columns needed.





}

function Report5() {
  const [data, setData] = useState<PositionEvaluator[]>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [rowVisibility, setRowVisibility] = useState<any[]>([]);

  const auth = useAuth();
  useEffect(() => {
    Distl.GetKvs(auth).then((dataList) => {
      setDataList(dataList);
      if (dataList && dataList.length > 0) {
        PositionsHttp.GetPositionsByBatch(auth, dataList[0].id).then((pes) => {
          if (pes?.length > 0) {
            setData(pes);

            var rowVis: any[] = [];

            table.getRowModel().rows.forEach(row => {
              rowVis.push({ key: row.original.id, value: false });
            });

            setRowVisibility(rowVis);
          }

        }).catch((err) => {
          console.log("caught error1: ", err);
        });
      }
    }).catch((err) => {
      console.log("caught error2: ", err);
    });

  }, [auth]);

  const GetOpt2 = (e: any) => {
    var distlId = e.target.value;
    PositionsHttp.GetPositionsByBatch(auth, distlId).then((data) => {
      setData(data);
    });
  };

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0;

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
      dir = compareItems(
        rowA.columnFiltersMeta[columnId]?.itemRank!,
        rowB.columnFiltersMeta[columnId]?.itemRank!
      );
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
  };

  const rerender = React.useReducer(() => ({}), {})[1];

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState('');

  const columns = React.useMemo<ColumnDef<PositionEvaluator, any>[]>(
    () => [
      {
        header: 'Report type here',
        footer: props => props.column.id,
        columns: [
          {
            accessorFn: row => row.primeUnderlier?.symbol,
            id: "primeUnderlier.symbol",
            cell: info => info.getValue(),
            header: () => 'Symbol',
            footer: props => props.column.id,
          },
          {
            header: () => 'Type',
            accessorKey: 'displayName',
            cell: info => info.getValue(),
            sortingFn: fuzzySort,
          },
          {
            header: () => 'Desc',
            accessorKey: 'description',
            cell: info => info.getValue(),
            footer: props => props.column.id,
            sortingFn: fuzzySort,
          },
          {
            header: () => 'Days Exp',
            accessorKey: 'daysToExpiration',
            cell: info => info.getValue(),
            footer: props => props.column.id,
            sortingFn: fuzzySort,
          },

          /*
          {
            accessorKey: '',
            cell: info => info.getValue(),
            footer: props => props.column.id,
            sortingFn: fuzzySort,
          },
          {
            accessorFn: row => row.,
            id: 'lastName',
            cell: info => info.getValue(),
            header: () => <span>Last Name</span>,
            footer: props => props.column.id,
          },
          {
            accessorFn: row => `${row.firstName} ${row.lastName}`,
            id: 'fullName',
            header: 'Full Name',
            cell: info => info.getValue(),
            footer: props => props.column.id,
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
          },
          */
        ],
      },

    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    manualPagination: true
  });

  return (
    <div className="p-2">
      <SelectDistl onChange={GetOpt2} data={dataList} />
      <div>
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search all columns..."
        />
      </div>
      <div className="h-2" />
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return [
              <tr className="hover" key={row.id} onClick={(event: any) => {
                rowVisibility[row.original.id] = !rowVisibility[row.original.id];
                setRowVisibility({ ...rowVisibility });
              }} >
                {
                  row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })
                }
              </tr>,
              rowVisibility[row.original.id] &&
              <tr key={row.original.id}>
                <td colSpan={row.getVisibleCells().length}>
                  <PositionEvaluatorComponent data={row.original} />
                </td>
              </tr>
            ];
          })}
        </tbody>
      </table>
    </div >
  );
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
            ? `(${column.getFacetedMinMaxValues()?.[0]})`
            : ''
            }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
            ? `(${column.getFacetedMinMaxValues()?.[1]})`
            : ''
            }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  );
}

function SelectDistl(props: any) {
  const onChange = (selectedId: any) => {
    props.onChange(selectedId);
  };

  if (props.data && Array.isArray(props.data)) {
    var kvsTable = props.data.map((p: any, i: number) => {
      var date = new Date(p.createDate);

      return (
        <option value={p.id} key={i}>
          {p.type + " - " + DateFuncs.ShortDateTimeWDay(date)}
        </option>
      );
    });
  }

  return (
    <select id="distlKvs" onChange={onChange}>
      {kvsTable}
    </select>
  );
}

export default Report5;