import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useAuth } from "react-oidc-context";
import { UserActionType } from "../entities/User";
import Settings from "../../config/settings.json";
import JSUtil from "../../util/jsUtil";
import { AdminUser } from "../../usermodule/adminUser";
import { User } from "../entities/User";

function AccountInfo(props) {
  const userContext = useContext(UserContext);
  const auth = useAuth();

  const clearSymbols = () => {
    localStorage.setItem("tickerHistory", null);
  };

  const signOut = () => {
    localStorage.removeItem(Settings.localStoreAutoSignIn);
    auth.removeUser().then(() => {
      userContext.dispatch({ action: UserActionType.LogOut });
    });
  };

  // TODO: this should be a service call into AuthHandler.tsx
  const login = () => {
    if (auth && !auth.user) {
      JSUtil.Log("calling signin popup from Home Page.", 2, "auth");
      auth.signinSilent().catch((error) => {
        console.log("SigninPopup from Home Page error:", error);
      });
    }
  };

  const toggleEmails = () => {
    const userView = new User({ ...userContext.state });
    userView.unsubscribeFromEmails = !userView.unsubscribeFromEmails;

    if (userView.unsubscribeFromEmails) {
      userView.unsubscribedDate = new Date();
    } else {
      userView.unsubscribedDate = null;
    }

    AdminUser.PostUser(userView, auth).then((user) => {
      userContext.dispatch({ action: UserActionType.SetUser, payload: user });
    });
  };

  return userContext.state.authenticated ? (
    <div className="">
      <div>
        {userContext.state.firstName} {userContext.state.lastName}
      </div>
      <div>{userContext.state.email}</div>
      <div>
        {!userContext.state.unsubscribeFromEmails ? (
          <button onClick={toggleEmails}>Unsubscribe from SaberFin Emails</button>
        ) : (
          <button onClick={toggleEmails}>Subscribe to SaberFin Emails</button>
        )}
      </div>
      <div>
        <button onClick={signOut}>Sign Out</button>
      </div>
      <div>
        <button onClick={clearSymbols}>Clear Latest Symbols List</button>
      </div>
    </div>
  ) : (
    <div>Please Sign In</div>
  );
}

export default AccountInfo;
